<style scoped>
.materials-item {
  margin-bottom: 20px;
  overflow: hidden;
}
.card-content {
  text-align: left;
  /* display: flex;
  flex-direction: column; */
}
</style>
<template>
  <Row :gutter="16">
    <Col
      v-for="(enrolledUnit, index) in enrolledUnits"
      :key="index"
      :lg="{ span: 6 }"
      :md="{ span: 8 }"
      :xs="{ span: 24 }"
      span="8"
      class="materials-item"
    >
      <!-- <Card style="height: 250px; width: 372px;"> -->
      <Card style="height: 100%; width: 100%">
        <div style="text-align: center">
          <img
            v-if="
              enrolledUnit.campus == 'Monash' ||
              enrolledUnit.campus == 'Monash University'
            "
            src="../../assets/monash.jpeg"
            @click="onCardSelect(enrolledUnit.course_code)"
            :style="{ cursor: 'pointer' }"
            height="100%"
            width="100%"
          />
          <img
            v-if="
              enrolledUnit.campus == 'University of Melbourne' ||
              enrolledUnit.campus == 'test_campus'
            "
            src="../../assets/melb_uni_IT.jpeg"
            @click="onCardSelect(enrolledUnit.course_code)"
            :style="{ cursor: 'pointer' }"
            height="100%"
            width="100%"
          />
          <!-- <p slot="title">
            {{ enrolledUnit.course_name }}
          </p>-->
          <a href="#" @click="onCardSelect(enrolledUnit.course_code)">{{
            enrolledUnit.course_name
          }}</a>
          <!-- <div v-html="enrolledUnit.description"></div> -->
        </div>
      </Card>
    </Col>
  </Row>
</template>
<script>
export default {
  data() {
    return {
      enrolledUnits: []
    };
  },
  methods: {
    onCardSelect: function (name) {
      this.$router.push({
        path: "/unitinfo",
        query: {
          id: name
        }
      });
    }
  },
  mounted: function () {
    const username = sessionStorage.getItem("username");
    if (username) {
      this.axios
        .get(`api/student/${username}/courses`)
        .then(resp => {
          const courseCodeList = resp.data
            .filter(item => item.paid)
            .map(paidItem => paidItem.course_code);
          return courseCodeList;
        })
        .then(async courseCodeList => {
          const courseList = [];
          for (const courseCode of courseCodeList) {
            const response = await this.axios.get(`api/course/${courseCode}`);
            const data = await response.data;
            courseList.push(data);
          }
          this.enrolledUnits = courseList;
        });
    }
  }
};
</script>
