var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Row',{attrs:{"gutter":16}},_vm._l((_vm.enrolledUnits),function(enrolledUnit,index){return _c('Col',{key:index,staticClass:"materials-item",attrs:{"lg":{ span: 6 },"md":{ span: 8 },"xs":{ span: 24 },"span":"8"}},[_c('Card',{staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticStyle:{"text-align":"center"}},[(
            enrolledUnit.campus == 'Monash' ||
            enrolledUnit.campus == 'Monash University'
          )?_c('img',{style:({ cursor: 'pointer' }),attrs:{"src":require("../../assets/monash.jpeg"),"height":"100%","width":"100%"},on:{"click":function($event){return _vm.onCardSelect(enrolledUnit.course_code)}}}):_vm._e(),(
            enrolledUnit.campus == 'University of Melbourne' ||
            enrolledUnit.campus == 'test_campus'
          )?_c('img',{style:({ cursor: 'pointer' }),attrs:{"src":require("../../assets/melb_uni_IT.jpeg"),"height":"100%","width":"100%"},on:{"click":function($event){return _vm.onCardSelect(enrolledUnit.course_code)}}}):_vm._e(),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.onCardSelect(enrolledUnit.course_code)}}},[_vm._v(_vm._s(enrolledUnit.course_name))])])])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }